import React, {Component,useState  } from 'react'

import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import { firestore } from 'firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";

import * as timeago from 'timeago.js';
import { reactReduxFirebase, getFirebase, isLoaded } from 'react-redux-firebase'

import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import {  isEmpty } from 'react-redux-firebase'

import  firebase  from 'firebase'


import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useFirebaseConnect } from 'react-redux-firebase'
import Loader from '../../components/other/Loader'

//import apiAccess from "../../store/api/Access"


import {Container,Grid,Box,Button} from '@material-ui/core';

//import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles((theme) => ({
    root: {
      //width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    Space:{
      flexGrow: 1,
    },
    NameCol:{
      paddingLeft:"20px"
    }
  }));


    const headCells = [
    { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
    { id: 'DeviceId', numeric: true, disablePadding: false, label: 'Device Id' },
    { id: 'dateLastEntries', numeric: true, disablePadding: false, label: 'Last Entry' },
    { id: 'dateCreated', numeric: true, disablePadding: false, label: 'Date Added' },
    { id: 'entries', numeric: true, disablePadding: false, label: 'Number of entries' },
  ];

  
var timerOut = null

const DevicesView = (props) => {

  const classes = useStyles();
  const {Devices,Groups,Group,match,Loaded} = props
  const [{orderBy,order}, setOrder] = React.useState({"orderBy":'name',"order":'desc'});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [update, setUpdate] = React.useState(false);
  const [anchorGroupMenu, setAnchorGroupMenu] = React.useState(null);

  
  var where = [["owner","==",props.user.uid]]

  if(props.match && props.match.params && props.match.params.id)
    where = [["owner","==",props.user.uid],["groups","array-contains",props.match.params.id]]

  useFirestoreConnect([
    { collection: 'Services', doc:"iot", storeAs: 'Devices',subcollections: [{collection: 'Devices',limit:30, where:where, orderBy: [orderBy,order]}]},
  ])

  
  if(!isLoaded())
    return(
      <main className={classes.root}>
          <h1>Loading Devices</h1>
          <Loader/>
      </main>
    )
    
  const handleGroupMenuClick = (event) => {
    setAnchorGroupMenu(event.currentTarget);
  };

  const handleGroupMenuClose = () => {
    setAnchorGroupMenu(null);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder({"orderBy":property,"order":(isAsc ? 'desc' : 'asc')});
  };

  var DeviceLastestUpdateTime = 0

const handleTimerOut = () => {
  setUpdate(!update)
};

/*
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };*/

  





  var GroupName = "All";
  if(match && match.params && match.params.id && match.params.id && Group && Group[0] && Group[0].id == match.params.id && Group[0].name)
  {
    GroupName = Group[0].name;
  }




  if(Devices==null) return null

        return (
            <main>
                <div className={classes.root}>
        
          <Toolbar >
            Group: 

            <Button aria-controls="groupMenu" aria-haspopup="true" onClick={handleGroupMenuClick}>
              {GroupName}
            </Button>
            <Menu
              id="groupMenu"
              anchorEl={anchorGroupMenu}
              keepMounted
              open={Boolean(anchorGroupMenu)}
              onClose={handleGroupMenuClose}
            >
              <MenuItem component={NavLink} to="/devices"  onClick={handleGroupMenuClose}>All</MenuItem>
              <Divider />
              {Groups && Groups.map((row, index) => {
                  return (
                    <MenuItem component={NavLink} to={"/groups/view/"+row.id} onClick={handleGroupMenuClose}>{row.name}</MenuItem>
                  )
                })
              }
            </Menu>
            <Button  aria-controls="groupMenu" aria-haspopup="true"  to="/group/add" >+</Button>

            <div className={classes.Space} />

            <Button  component={NavLink} to="/devices/add" variant="contained">Add Device</Button>

        </Toolbar>


        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell.id)}
                        >
                          

                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                          ))}
                  </TableRow>
                </TableHead>

            <TableBody>
              {
                  Devices.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  
                  var dateCreated_timeAgo="";
                  var dateLastEntries_timeAgo="";

                  if(row.dateCreated)
                    dateCreated_timeAgo = timeago.format(row.dateCreated.seconds*1000, 'time');
                  
                  if(row.dateLastEntries)
                    dateLastEntries_timeAgo = timeago.format(row.dateLastEntries.seconds*1000, 'time');
                  

                  if((row.dateCreated && DeviceLastestUpdateTime < row.dateCreated.seconds) || (row.dateLastEntries && DeviceLastestUpdateTime < row.dateLastEntries.seconds))
                  {
                    if(row.dateCreated && DeviceLastestUpdateTime < row.dateCreated.seconds)
                      DeviceLastestUpdateTime = row.dateCreated.seconds
                    
                    if(row.dateLastEntries && DeviceLastestUpdateTime < row.dateLastEntries.seconds)
                      DeviceLastestUpdateTime = row.dateLastEntries.seconds

                    if(timerOut)
                      window.clearTimeout(timerOut)

                    const secondsAgo =  new Date().getTime() / 1000 - DeviceLastestUpdateTime
                    if(secondsAgo <= 60)
                      timerOut = setTimeout(handleTimerOut, 1000);
                    else if(secondsAgo <= 3600)
                      timerOut = setTimeout(handleTimerOut, (60-secondsAgo%60)*1000);
                    else if(secondsAgo <= 86400)
                      timerOut = setTimeout(handleTimerOut, (3600-secondsAgo%3600)*1000);
                    else 
                      timerOut = setTimeout(handleTimerOut, 86400000);
                  }

                  var entries = row.entries? row.entries : 0
                  if(entries>100000000)
                    entries = Math.round(entries/1000000)+"M"
                  else if(entries>1000000)
                      entries = (entries/1000000).toFixed(1)+"M"
                  else if(entries>10000)
                      entries = Math.round(entries/1000)+"K"
                  else if(entries>1000)
                      entries = (entries/1000).toFixed(1)+"K"

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell className={classes.NameCol} component="th" id={labelId} scope="row" padding="none">
                        <NavLink to={"/devices/view/"+row.id}>{row.name}</NavLink>
                      </TableCell>
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{dateLastEntries_timeAgo}</TableCell>
                      <TableCell align="left">{dateCreated_timeAgo}</TableCell>
                      <TableCell align="left">{entries}</TableCell>

                      
                    </TableRow>
                  );
                })}
              
            </TableBody>
          </Table>
        </TableContainer>
        {/*<TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Devices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />*/}
    </div>
            </main>
        );
}








const mapStateToProps = (state, ownProps) => {
    return {
        user:firebase.auth().currentUser,
        Devices: state.firestore.ordered.Devices,
        Groups: state.firestore.ordered.Groups,
        Group: state.firestore.ordered.Group
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props =>{

      if(props.match && props.match.params && props.match.params.id)
      {
        return[
          { collection: 'Services', doc:"iot",storeAs: 'Groups',subcollections: [{ collection: 'Groups', where:[["owner","==",props.user.uid]]}]},
          { collection: 'Services', doc:"iot",storeAs: 'Group',subcollections: [{ collection: 'Groups', doc:props.match.params.id}]},

        ];
      }
      
      return [
        { collection: 'Services', doc:"iot",storeAs: 'Groups',subcollections: [{ collection: 'Groups',  where:[["owner","==",props.user.uid]]}]},

        
      ]
  })
)(DevicesView);













function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



/*
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));



/*
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>

        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};*/
