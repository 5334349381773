import axios from 'axios'
import { getFirebase } from 'react-redux-firebase'



var baseURL = 'https://api.iot.spried.com';
var baseAPIURL = 'https://api.spried.com';
//baseURL = 'http://localhost:5001/spried-iot/us-central1/api/';
/*
if(process.env.REACT_APP_ENV === "development" && process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development")
{
    baseURL = 'http://localhost:5001/spried-iot/us-central1/api';
}*/

const apiAccess = (props) => { 
    
    const firebase = getFirebase();
    const token = firebase.auth().currentUser.getIdToken();

    //const token = firebase.auth().currentUser.getIdToken()
    console.log("token")
    console.log(token)

    var axiosCreateVar = {
            baseURL: baseURL,
            headers: {}
        }

    if(token)
        axiosCreateVar.headers["Authorization"] = token.i;

    return axios.create(axiosCreateVar)
}

export default apiAccess
export { baseURL,baseAPIURL };