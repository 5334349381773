import React from 'react'
import { NavLink } from 'react-router-dom'
import Container from '@material-ui/core/Container';


const contact = () => {
    
        return (
            <Container>
                            <h1><strong>Error</strong> Could not find or load page.</h1>
                            <p>Please try again later.</p>
                            {/*<p>You may want to see:</p>
                            <div class="row">
                                <div class="col-sm">
                                    <NavLink to="/" className="nav-link py-2 site-Link " id="" name="">Home</NavLink>
                                </div>
                                <div class="col-sm">
                                    <NavLink to="/stuff" className="nav-link py-2 site-Link  " id="" name="">Stuff</NavLink>
                                </div>
                                <div class="col-sm">
                                    <NavLink to="/stuff/add" className="nav-link py-2 site-Link " id="" name="">Add a broken stuff</NavLink>
                                </div>
        </div>*/}
            </Container>
        )
    
}
export default contact
