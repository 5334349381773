import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'
import Container from '@material-ui/core/Container';


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'

import Loader from "../other/Loader"

import {
    Box,
    Button,
    CircularProgress,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    MenuItem,
  Checkbox,
  Toolbar,
  FormControlLabel,
  Typography
      } from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //marginBottom:"20px"
    },
    HeaderBox:{
        padding: " 0 20px;",
    },
    ToolBar:{
        padding:0
    },
    DiviceHeader:{
        margin:"10px 0 0 0"
    },
    MenuItem:{
      color:"#8f9297",
      fontWeight: "700;",
      padding:0,
      margin:0,
      marginRight:"10px",
      textAlign:"left",

      "&:hover":{
        background:"none",
        color:"#555"
      }
    },
    MenuItemSelected:{
      color:"#000"
    }
});

const DeviceHeader = (props) => {
    const classes = useStyles();
const {DeviceId,DevicesDetails} = props;
    const [values, setValues] = useState({
        firstName: 'Katarina',
      });
    
    
    const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
      };

if(!DevicesDetails)
    return (<Box className={classes.loading}>
        <CircularProgress  className={classes.CircularProgress}/>
        <h4>Loading</h4>

    </Box>);

    return (
<div className={classes.root}>
    <Box className={classes.HeaderBox}>
                <h2 className={classes.DiviceHeader}>{DevicesDetails.name}</h2>
                
                <Toolbar className={classes.ToolBar}>
                    <Button exact component={NavLink} to={"/devices/view/" + DeviceId} className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
                        Display
                    </Button >
                    <Button exact component={NavLink} to={"/devices/view/" + DeviceId +"/data"} className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
                        Data
                    </Button >
                    <Button exact component={NavLink} to={"/devices/view/" + DeviceId +"/errors"} className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
                        Errors
                    </Button >
                    {/*<Button exact component={NavLink} to={"/devices/view/" + DeviceId +"/action"} className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
                        actions
                    </Button >
                    {/*<Button exact component={NavLink} to={"/devices/view/" + DeviceId +"/control"} className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
                        Control
    </Button >*/}
                    <Button exact component={NavLink} to={"/devices/view/" + DeviceId +"/settings"} className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
                        Settings
                    </Button >
                </Toolbar>
            </Box>
        
            <Divider />
</div>
        )
}


const mapStateToProps = (state) => {

    var DevicesDetails = null;
    if(state.firestore.ordered.DevicesDetails)
        DevicesDetails = state.firestore.ordered.DevicesDetails[0];

    return {
        user:firebase.auth().currentUser,
        DevicesDetails: DevicesDetails,
        //Groups: state.firestore.ordered.Groups,
        //Group: state.firestore.ordered.Group,
    }
}

export default compose(
    connect(mapStateToProps),
    
    firestoreConnect(props =>{
        return[
          { collection: 'Services', doc:"iot",storeAs: 'DevicesDetails',subcollections: [{collection: 'Devices',doc:props.DeviceId}]},
         // { collection: 'Groups', storeAs: 'Groups'},
         // { collection: 'Groups', storeAs: 'Group', doc:props.match.params.id},
        ];
  })
)(DeviceHeader);

