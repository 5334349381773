import React from 'react'
import { withStyles } from '@material-ui/core/styles';


import * as timeago from 'timeago.js';

import {
    Box,
  Typography
      } from '@material-ui/core';

      import MuiAccordion from '@material-ui/core/Accordion';
      import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
      import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
      

import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    }
});




const Settings = {
    "Name":"Logs",
    "Display":true,
    "options":
    {
        "view":null,
        "settings":[
          {
            "id":"accordion",
            "type":"switch",
            "default": false,
            "name":"Minimised"
          },
          {
            "id":"size",
            "type":"number",
            "default": 3,
            "name":"Number of records"
          },
          {
            "type":"html",
            "html":"If Number of records is greater then 3, the log allways Minimised. If Number of records is 1, the log allways maximised. Log can not display more than 10 items."
          }
        ],
        "grid":true,
    }
}



const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  
const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);





  var timerOut = null
const View = ({DeviceData,DevicesDetails,Settings}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(null);
    const [update, setUpdate] = React.useState(false);
    
    const size = Settings.size &&  Settings.size <= 10 ?  Settings.size : 3;

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };


    if(timerOut)
      window.clearTimeout(timerOut)

    const handleTimerOut = () => {
      setUpdate(!update)
    };

    if(DeviceData && DeviceData[0])
    {
      const secondsAgo =  new Date().getTime() / 1000 - DeviceData[0].dateCreated.seconds
      if(secondsAgo <= 60)
        timerOut = setTimeout(handleTimerOut, 1000);
      else if(secondsAgo <= 3600)
        timerOut = setTimeout(handleTimerOut, (60-secondsAgo%60)*1000);
      else if(secondsAgo <= 86400)
        timerOut = setTimeout(handleTimerOut, (3600-secondsAgo%3600)*1000);
      else 
        timerOut = setTimeout(handleTimerOut, 86400000);
    }

    return (
            <Box>
                
            {DeviceData&& DeviceData.slice(0, size).map((row, index) => {
                
                
                var dateCreated_timeAgo="";
                var dateCreated="";

                if(row.dateCreated)
                {
                  dateCreated_timeAgo = timeago.format(row.dateCreated.seconds*1000, 'time');
                  dateCreated = new Date(row.dateCreated.seconds*1000);
                }
                
                if((!Settings.accordion && size<=3) || size == 1)
                return(<Box>
                    <Typography>{dateCreated_timeAgo} — {dateCreated.toLocaleString("en-AU")}</Typography>
                    <Typography>
                        <pre id="json">
                            {JSON.stringify(row.body, null, 2)}
                        </pre>
                    </Typography>
                </Box>)


                return (<Accordion square expanded={expanded === row.id} onChange={handleChange(row.id)}>
                            <AccordionSummary >
                            <Typography>{dateCreated_timeAgo} — {dateCreated.toLocaleString("en-AU")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Typography>Body</Typography>
                                    <Typography>
                                        <pre id="json">
                                            {JSON.stringify(row.body, null, 2)}
                                        </pre>
                                    </Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>)
                })
            }

            </Box>
        )
}



export default {
    Settings,
    View
};