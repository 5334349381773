import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    firebaseui:{
        height:"400px"
    }
});

const Login = () => {
    const classes = useStyles();
    const firebase = getFirebase()
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/',
        // We will display Google and Facebook as auth providers.
        //provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,      
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ], 
        
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                var isNewUser = authResult.additionalUserInfo.isNewUser;
                if(isNewUser)
                {
                    window.location.href = "https://"+window.location.hostname+"/documents/getting-started";
                    //window.location.href = "https://iot.spried.com/documents";
                }
                else
                {
                    window.location.href = "https://"+window.location.hostname+"/devices";
                }
                return false;
            },
            uiShown: function() {
            }
        }
    };

    var user = firebase.auth().currentUser;
    if (user)
    return <Redirect to="/" />

        return (
            <main >
                <Container>
                    <h1>Login</h1>
                    <Box id="firebaseui-auth-container" className={classes.firebaseui}>
                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
                    </Box>
                </Container>
            </main>
        )
    
}
export default Login
