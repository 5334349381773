import React, {Component,useState  } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'
import TimeAgo from 'javascript-time-ago'
import { NavLink,withRouter  } from 'react-router-dom'
import en from 'javascript-time-ago/locale/en'
  

import apiAccess from "../store/api/Access"
import Loader from '../components/other/Loader'


import HorizontalAds from "../components/ads/HorizontalAds.js";
import VerticalAds from "../components/ads/VerticalAds.js";
//import ImageGallery from "../../components/issues/ImageGallery.js";
//import VerticalAds from "../../components/ads/VerticalAds.js";
//import NextPrevious from "../../components/other/NextPrevious.js";

import { makeStyles } from "@material-ui/core/styles";
import {Container,Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';


import mainStyle from "../assets/mainStyle";
const useStyles = makeStyles( {
  ... mainStyle,
  root:{
      textAlign: "center;",
      paddingTop: "20px;",
      marginBottom: "100px;",
  },
  SlideBox:{
    flexShrink: 1,
    borderRight:"1px solid #1B263B"
  },
  DocumentBox:{
    flexShrink: 1,
    margin:"0 20px"
  }
});
 
const NotificationsView = (props) => {
        
    const { Notifications,user}=props;
    const classes = useStyles();
    
    if (!isLoaded(Notifications)) {
        return(
          <main className={classes.root}>
              <h1>Loading Notifications</h1>
              <Loader/>
          </main>
        )
    }

    if (isEmpty(Notifications)) {
        return(
          <main className={classes.root}>
              <h1>No Notifications</h1>
              <p>There are no notifications.</p>
          </main>
        )
    }
    
    TimeAgo.addLocale(en)
    const timeAgo = new TimeAgo('en-US');



    return (
        <Container>
            <h1>Notifications</h1>

            <Box>
                {Notifications.map((item, index) => {
                    
                    console.log(item)

                    var created_timeAgo
                    if(item.date)
                    {
                    created_timeAgo = timeAgo.format(item.date.seconds*1000, 'time');
                    }
                    return (<Box component={NavLink} to={item.url} sx={{ display: 'flex', p: 1, bgcolor: 'background.paper' }}>
                    <Box><Box>{item.name}</Box><Box>{created_timeAgo?created_timeAgo:null}</Box><Box>{item.date.toDate().toDateString()}</Box></Box>
                        <Box>{item.Info}</Box>
                        <Box>{item.read?"Read":"Unread"}</Box>
                        </Box>)

                })}

            </Box>
        </Container>)
}

  const mapStateToProps = (state) => {

    return {
        user:firebase.auth().currentUser,
        Notifications: state.firestore.ordered.Notifications,
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect(props =>{
        
        var UID = firebase.auth().currentUser.uid

        return[
            { collection: 'Users', doc:UID, storeAs: 'Notifications', subcollections: [{ collection: 'Notifications', where:[["service","==","iot"]]}] },
        ]
    })
) (NotificationsView);