import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import DeviceHeader from '../../../components/header/DeviceHeader'
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


import * as timeago from 'timeago.js';

import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'

import { useFirestoreConnect } from 'react-redux-firebase'

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    MenuItem,
  Checkbox,
  Toolbar,
  FormControlLabel,
  Typography
      } from '@material-ui/core';

      import MuiAccordion from '@material-ui/core/Accordion';
      import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
      import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
      
import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../../assets/mainStyle";
const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    },
    HeaderBox:{
        padding: " 0 20px;",
    },
    ToolBar:{
        padding:0
    },
    MenuItem:{
      color:"#8f9297",
      fontWeight: "700;",
      padding:0,
      margin:0,
      marginRight:"10px",
      textAlign:"left",

      "&:hover":{
        background:"none",
        color:"#555"
      }
    },
    MenuItemSelected:{
      color:"#000"
    },
    LoadMoreBox:{
      marginTop:"20px",
      marginBottom:"60px",
      textAlign:"center"
    },
    LoadMoreButton:{
      padding:"10px 20px"

    }
});


const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  
const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);



var timerOut = null

const View = (props) => {
    const classes = useStyles();
    const {match,DeviceData} = props;
    const [expanded, setExpanded] = React.useState(null);
    const [update, setUpdate] = React.useState(false);
    const [LimitCount, setLimitCount] = React.useState(20);

    useFirestoreConnect([
      { collection: 'Services', doc:"iot", storeAs: 'DeviceData',limit:LimitCount,subcollections: [
        { collection: 'Devices',doc:match.params.id},
        { collection: 'Data',orderBy:["dateCreated","desc"]},
      ]},
    ])


    const handleLoadMore = () => {
      setLimitCount(LimitCount+10);
    };

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };


    if(timerOut)
      window.clearTimeout(timerOut)

    const handleTimerOut = () => {
      setUpdate(!update)
    };

    if(DeviceData && DeviceData[0])
    {
      const secondsAgo =  new Date().getTime() / 1000 - DeviceData[0].dateCreated.seconds
      if(secondsAgo <= 60)
        timerOut = setTimeout(handleTimerOut, 1000);
      else if(secondsAgo <= 3600)
        timerOut = setTimeout(handleTimerOut, (60-secondsAgo%60)*1000);
      else if(secondsAgo <= 86400)
        timerOut = setTimeout(handleTimerOut, (3600-secondsAgo%3600)*1000);
      else 
        timerOut = setTimeout(handleTimerOut, 86400000);
    }


    var offset = new Date().getTimezoneOffset();
    return (
        <main className={classes.root}>
            <DeviceHeader DeviceId={match.params.id}/>

            {DeviceData&& DeviceData.map((row, index) => {
                
                
                var dateCreated_timeAgo="";
                var dateCreated="";

                if(row.dateCreated)
                {
                  dateCreated_timeAgo = timeago.format(row.dateCreated.seconds*1000, 'time');
                  dateCreated = new Date(row.dateCreated.seconds*1000);
                }

                return (<Accordion square expanded={expanded === row.id} onChange={handleChange(row.id)}>
                            <AccordionSummary >
                            <Typography>{dateCreated_timeAgo} — {dateCreated.toLocaleString("en-AU")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                <Typography>Body</Typography>
                                <Typography>
                                    <pre id="json">
                                        {JSON.stringify(row.body, null, 2)}
                                    </pre>
                                </Typography>
                                {/*}
                                <Typography>Headers</Typography>
                                <List>
                                    
                                { row.headers && Object.keys(row.headers).map((headerRow, headerIndex) => {


                                        const value = row.headers[headerRow];

                                        return (<ListItem>
                                                    <ListItemText
                                                        primary={headerRow}
                                                        secondary={value}
                                                    />
                                                </ListItem>)
                                    })
                                }

                                </List>
                              {*/}
                                </Box>
                            </AccordionDetails>
                        </Accordion>)
                })
            }
            <Box onClick={handleLoadMore} className={classes.LoadMoreBox}>
              <Button className={classes.LoadMoreButton}>Load more</Button>
            </Box>

        </main>
        )
}

const mapStateToProps = (state) => {
    
    var DevicesDetails = null;
    var DeviceData = null;
    
    if(state.firestore.ordered.DevicesDetails)
        DevicesDetails = state.firestore.ordered.DevicesDetails[0];

    if(state.firestore.ordered.DeviceData)
        DeviceData = state.firestore.ordered.DeviceData;

    return {
        user:firebase.auth().currentUser,
        DevicesDetails: DevicesDetails,
        DeviceData: DeviceData,
        //Group: state.firestore.ordered.Group,
    }
}
export default compose(
    connect(mapStateToProps),
    
    firestoreConnect(props =>{
        return[
          { collection: 'Services', doc:"iot", storeAs: 'DevicesDetails',subcollections: [{ collection: 'Devices', doc:props.match.params.id}]},

          /*{ collection: 'Devices', storeAs: 'DeviceData',doc:props.match.params.id,
          subcollections: [
            { collection: 'Data',orderBy:["dateCreated","desc"],limit:10},
          ]},*/
        ];
  })
)(View);
