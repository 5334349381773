import React from 'react'
import { NavLink } from 'react-router-dom'



import {Typography} from '@material-ui/core';


import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    }
});




const Settings = {
    "Name":"Welcome"
}


const View = (props) => {
    const classes = useStyles();
    const {Data } = props;

    return (
        <Typography variant="body2" color="textSecondary" component="p">
            Welcome to this device.
            <br/>
            <NavLink to="/documents/widgets" variant="outlined" color="primary">
            Read About Widgets
            </NavLink>
        </Typography>
        )
}



export default {
    Settings,
    View
};