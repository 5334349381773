import React from 'react'

import { Scatter ,Line   } from 'react-chartjs-2';

import * as timeago from 'timeago.js';
import {
    Box,
      } from '@material-ui/core';


import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    }
});




const Settings = {
    "Name":"Chart",
    "Display":true,
    "options":
    {
        "view":null,
        "settings":[
            {
              "id":"datasets",
              "type":"array",
              "settings":[
                {
                    "id":"id",
                    "type":"text",
                    "name":"ID"
                  }
              ]
            }
          ],
        "grid":true,
    }
}



const View = ({DeviceData,DevicesDetails,Settings}) => {
    const classes = useStyles();

    if(!DeviceData || !DeviceData[0])
        return (
            <Box>
                No Data.
            </Box>
        )

  



const rand = () => Math.round(Math.random() * 20 - 10);



var data = {
    labels: [],
  datasets: [  ],
};


var options = {
  
    responsive: true,
    tooltips: {
        mode: 'label'
    },
    elements: {
        line: {
            fill: false
        }
    },
    /*scales: {
        xAxes: [
            {
                display: true,
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                }
            }
        ],
        //yAxes: []
    }*/
};


Settings.datasets && Settings.datasets.forEach(element => {

    var count = 0
    data.datasets.forEach(DataElement => {
        if(DataElement.id == element.id)
            count++
    })

    if(count > 0)
        return

    data.datasets.push({
        id:element.id,
        label: element.label ? element.label : element.id,
        data: [],
        fill: element.fill ? element.fill : false,
        backgroundColor: element.backgroundColor ? element.backgroundColor : 'rgb(54, 162, 235)',
        borderColor: element.borderColor ? element.borderColor : 'rgba(54, 162, 235, 0.2)',
        yAxisID: element.id + '-y-axis',
    })

/*
    options.scales.yAxes.push({
        type: element.log ? 'log' : 'linear',
        display: true,
        position: element.position ? element.position : 'right',
        id: element.id + '-y-axis',
        
        gridLines: {
            display: element.gridLinesDisplay ? element.gridLinesDisplay : false,
        },
        labels: {
            show: element.showLabels ? true : false
        },
        ticks:{}
    })*/

});


for (var i = DeviceData.length - 1; i >= 0; i--) 
{
    if(Settings.timeago == true)
    {
        data.labels.push(timeago.format(DeviceData[i].dateCreated.seconds*1000, 'time'))
    }
    else
    {
        const dateCreated = new Date(DeviceData[i].dateCreated.seconds*1000);
        data.labels.push(dateCreated.toLocaleString("en-AU"))
    }

    data.datasets.forEach(element => {
        if(element.id in DeviceData[i].body)
        {
            element.data.push(DeviceData[i].body[element.id])
        }
        else
        {
            element.data.push(null)
        }
    });

}



    return (
            <Box>
                <Line   data={data} options={options} />
            </Box>
        )
}



export default {
    Settings,
    View
};