import React, {Component,useState  } from 'react'
import { Link, Redirect } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import {Container,FormControl,TextField,Button,Paper} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../components/other/Loader'
import {SubmitContact} from '../store/actions/ContactAction'
import { connect } from 'react-redux'
import {  GoogleReCaptchaProvider,  GoogleReCaptcha} from 'react-google-recaptcha-v3';


import { NavLink } from 'react-router-dom'
import {baseAPIURL} from "../store/api/Access"
import  firebase  from 'firebase'
import axios from 'axios'



const useStyles = makeStyles({
    root:{
        padding:"0 20px"
    },
    /*title:{
        fontSize: "45px;",
        color: "#374657;",
        fontWeight: "700;",
        fontFamily: "'Titillium Web', sans-serif",
        WebkitFontSmoothing: "antialiased",
        margin:"30px 0"
    },*/
    textField: {
      marginBottom: '15px',
    },/*
    SubmitButton:{
        backgroundColor:"#19A78E",
        fontSize: "16px;",
        color: "#fff;",
        fontWeight: "700;",
        textDecoration: "none",
        padding: "13px;",
        borderRadius: "50px;",
        marginBottom: '30px',
        width:"100px",
        "&:hover":{
            backgroundColor:"#374657",
        }
    },
    box:{
        border:"#19A78E solid 1px",
        padding: '30px',
        marginBottom:"100px"
    }*/
  });

const Contact = ({SubmitContactFunction}) => {
    
    const classes = useStyles();
    
    const [values, setValues] = useState({});
    const [device, setdevice] = useState({});
    const [{Loading,finished,error,timeoutTimer}, setForm] = useState({Loading:false, finished:false,error:false,timeoutTimer:null});

    if(finished)
        return (
            <main className={classes.root}>
                <h1>Contact/ideas</h1>

                <h3>Thank you</h3>

                <Button component={NavLink} to="/" variant="contained" color="primary">
                    Go home
                </Button>

            </main>)


    if(error)
        return (
            <main className={classes.root}>
                <h1>Contact/ideas</h1>
                <h3>Error</h3>

                    <h3>Try again later.</h3>
            </main>)

    if(Loading)
        return (
            <main className={classes.root}>
                <h1>Contact/ideas</h1>
                <Loader/>
            </main>)


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    
    const handleVerify=token => {
        console.log(token)
        setValues({
            ...values,
            token: token
        });
    }

    const handleUpdate = (success=false) => {
        if(timeoutTimer)
            clearInterval(timeoutTimer);
        //setForm({Loading:false, finished:true, error:false,timeoutTimer:true});
    };

    const handleTimeout = () => {
        console.log()
        setForm({Loading:false, finished:false, error:true,timeoutTimer:null});
    };

    const handleSubmit = (event) => { 
        event.preventDefault();
        const timeoutTimer = setTimeout(handleTimeout, 30000)
        setForm({Loading:true, finished:false, error:false,timeoutTimer:timeoutTimer});

        if(firebase.auth().currentUser)
        {
            firebase.auth().currentUser.getIdToken().then(function(idToken) {

                handleForm(idToken);

            }).catch(function(error) {
                    if(timeoutTimer)
                        clearInterval(timeoutTimer);
                    setForm({Loading:false, finished:false, error:true,timeoutTimer:null});
            });
        }
        else
        {
            handleForm(null);
        }
    };

    const handleForm = (idToken) =>{
        
        var MessageData = {};
        var HeaderData = {};
        MessageData.service = "iot";

        if(values.name)
            MessageData.name = values.name;

        if(values.token)
            MessageData.token = values.token;
    
        if(values.email)
            MessageData.email = values.email;
            
        if(values.message)
            MessageData.message = values.message;

        if(values.idToken)
        {
            MessageData.userIdToken = values.idToken;
            HeaderData = {"headers":{"Authorization": idToken}};
        }


        axios.post('https://api.spried.com/contact',MessageData,HeaderData).then(Result => {
            if(timeoutTimer)
                clearInterval(timeoutTimer);
            
            console.log(Result)
            if(Result.data.device)
                setdevice(Result.data.device);

            setForm({Loading:false, finished:true, error:false,timeoutTimer:null});
            
        }).catch(err => {
            //setError(err);
            if(timeoutTimer)
                clearInterval(timeoutTimer);
            setForm({Loading:false, finished:false, error:true,timeoutTimer:null});
        });  
    }

    return (
        <main className={classes.root}>
            <h1>Contact/ideas</h1>
            <p >Please contact us any time about anything.</p>
            <GoogleReCaptchaProvider reCaptchaKey="6Ldt0FkdAAAAAJ7wk1tteGK4IxMlTS9CXJhUD3DT">
                <form onSubmit={handleSubmit} action="post">
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth
                        className={classes.textField}
                    />
                    
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        variant="outlined"
                        onChange={handleChange}
                        type="email"
                        fullWidth
                        className={classes.textField}
                    />

                    <TextField
                        required
                        id="message"
                        name="message"
                        label="Message"
                        onChange={handleChange}
                        multiline
                        rows={6}
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                    />

                    {values && !values.token ? <GoogleReCaptcha onVerify={handleVerify} /> : null}
                        
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        >
                        Submit
                    </Button>
                </form>
            </GoogleReCaptchaProvider>
        </main>
    )
}
  
  export default (Contact);