import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import DeviceHeader from '../../../components/header/DeviceHeader'


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    MenuItem,
  Checkbox,
  Toolbar,
  FormControlLabel,
  Typography
      } from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../../assets/mainStyle";
const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    },
    HeaderBox:{
        padding: " 0 20px;",
    },
    ToolBar:{
        padding:0
    },
    MenuItem:{
      color:"#8f9297",
      fontWeight: "700;",
      padding:0,
      margin:0,
      marginRight:"10px",
      textAlign:"left",

      "&:hover":{
        background:"none",
        color:"#555"
      }
    },
    MenuItemSelected:{
      color:"#000"
    }
});

const View = (props) => {
    const classes = useStyles();
const {match} = props;

    return (
        <main className={classes.root}>
            <DeviceHeader DeviceId={match.params.id}/>


        </main>
        )
}

const mapStateToProps = (state) => {
    
    var DevicesDetails = null;
    if(state.firestore.ordered.DevicesDetails)
        DevicesDetails = state.firestore.ordered.DevicesDetails[0];

    return {
        user:firebase.auth().currentUser,
        DevicesDetails: DevicesDetails,
        //Groups: state.firestore.ordered.Groups,
        //Group: state.firestore.ordered.Group,
    }
}

export default compose(
  connect(mapStateToProps),
  
  firestoreConnect(props =>{
      return[
        { collection: 'Services', doc:"iot", storeAs: 'DevicesDetails',subcollections: [{ collection: 'Devices', doc:props.match.params.id}]},
        /*{ collection: 'Devices', storeAs: 'DeviceData',doc:props.match.params.id,
        subcollections: [
          { collection: 'Data',orderBy:["dateCreated","desc"],limit:10},
        ]},*/
      ];
})
)(View);