import React, {Component,useState  } from 'react'
import { NavLink,Redirect } from 'react-router-dom'
import DeviceHeader from '../../../components/header/DeviceHeader'
import Loader from '../../../components/other/Loader'
import ControlFormatForm from '../../../components/other/ControlFormatForm'
import axios from 'axios'
import {baseURL} from "../../../store/api/Access"
import { reactReduxFirebase, getFirebase, isLoaded } from 'react-redux-firebase'


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'

import { firestore } from 'firebase'

import Select from '@material-ui/core/Select';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Container from '@material-ui/core/Container';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Button,
    TextField ,
    FormControl,
    Card,
    InputLabel,
    CardContent,
    IconButton,
    Divider,
    Grid, 
    MenuItem,
  Checkbox,
  Toolbar,
  FormControlLabel,
  Typography
      } from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../../assets/mainStyle";


const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    },
    Content:{
        //padding: " 0 20px;",
        //marginTop:"20px",
        padding: "20px 20px;",
        borderBottom: "1px rgba(0, 0, 0, 0.12) solid;"
    },
    ToolBar:{
        padding:0
    },
    DiviceHeader:{
        margin:"10px 0 0 0"
    },
    textField: {
      marginBottom: '15px',
    },
    formControl:{
        marginBottom: '15px',
    },
    MenuItem:{
      color:"#8f9297",
      fontWeight: "700;",
      padding:0,
      margin:0,
      marginRight:"10px",
      textAlign:"left",

      "&:hover":{
        background:"none",
        color:"#555"
      }
    },
    MenuItemSelected:{
      color:"#000"
    },
    ResponseListItem:{
        background:"#eee",
        borderBottom: "1px rgba(0, 0, 0, 0.12) solid;"

    },
    deleteGrid: {
      justifyContent: "flex-end",
      alignItems: "flex-end",
      textAlign: "right"
    },
    AddBox:{
      marginTop:"20px",
      marginBottom:"60px",
      textAlign:"center"
    },
    AddButton:{
      padding:"10px 20px"

    },
    ResponseArray:
    {
        display: 'flex',
        margin:"40px 0"
    }
});




const inputTypes=[
    "Variable",
    "Value",
    "Time",
    "Date",
    "submit",
]


const View = (props) => {
    const classes = useStyles();
    const {match,DevicesDetails,firestore} = props
    const [values, setValues] = useState(null);
    const [redirect, setRedirect] = useState(false);

    if(redirect)
        return(<Redirect to="/" />)

    if(isLoaded() && !values && DevicesDetails)
    {
        setValues(DevicesDetails);
    }

    if(values && !values.control)
    {
        setValues({
            ...values,
            "control": [{"name":"submit","type":"submit"}]
        });
    }
    const ControlList = values && values.control ? values.control : [];

    
    if(!values)
    {
        return (
            <main className={classes.root}>
                <Loader/>
            </main>)
    }


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    
    const ControlhandleChange = (event,index) => {
        var tempControl = [...values.control]
        console.log(event.target)
        tempControl[index] = {
            ...values.control[index],
            [event.target.name]: event.target.value
        }
        //[event.target.name] = event.target.value

        setValues({
            ...values,
            "control":tempControl
        });
    };



    const controlhandleAdd = (event) => {

        const tempControl = [
            ...values.control,
            {"name":"","type":""}
        ]
        setValues({
            ...values,
            "control":tempControl
        });
    };

    

    


    const handleSubmit = (event) => { 
        event.preventDefault();
        
        firestore.collection("Services").doc("iot").collection("Devices").doc(match.params.id).update({
            name:values.name,
            control:values.control,
            format:values.format ? values.format :"json"
        }).then(() => {
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    };


    const handleDelete = (event) => { 
        event.preventDefault();
        var result = window.confirm("Are you sure you want to delete this Device?");
        if (result)
        {
            firestore.collection("Services").doc("iot").collection("Devices").doc(match.params.id).delete().then(() => {
                alert("Document successfully deleted!")
                setRedirect(true)
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });
        }
    };


    
    const ControlhandleDelete = (event,index) => { 
        var tempControl = [...values.control]
        tempControl.splice(index, 1); 
        //const tempControl = values.control.filter((item) => item.id !== index);

        setValues({
            ...values,
            "control":tempControl
        });

    };



    return (
        <main className={classes.root}>
            <DeviceHeader DeviceId={match.params.id}/>
        
            <Box className={classes.Content}>
                <h2 className={classes.SectionHeading}>General</h2>

                <TextField
                    fullWidth
                    label="Device name"
                    name="name"
                    onChange={handleChange}
                    required
                    value={values && values.name  ? values.name:null}
                    variant="outlined"
                    className={classes.textField}
                    />

                
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Box>



            <Box className={classes.Content}>
                <h2 className={classes.SectionHeading}>Response Format</h2>

                <FormControl 
                    fullWidth
                    className={classes.formControl}>
                    <InputLabel>Format</InputLabel>
                    <Select
                    fullWidth
                        labelId="format"
                        id="format"
                        name="format"
                        value={values.format ? values.format : "json"}
                        onChange={handleChange}
                    >
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="json">JSON</MenuItem>
                        <MenuItem value="html">HTML</MenuItem>
                        <MenuItem value="xml">XML</MenuItem>
                    </Select>
                </FormControl>
                

                
                <FormControl 
                    fullWidth
                    className={classes.formControl}>
                {ControlList && ControlList.map((item, index)=>{
                    return (<Box className={classes.ResponseArray}>
                                <Box  style={{ flexGrow: 1 }}> 
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <TextField id="standard-basic" name="name" onChange={(event) => ControlhandleChange(event,index)} label="Name" fullWidth value={item && item.name ?item.name:null}/>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Select
                                            style={{marginTop:"16px"}}
                                                fullWidth
                                                label="Type"
                                                displayEmpty
                                                value={item?item.type:null}
                                                name="type"
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                renderValue={(selected) => {
                                                if (!selected)
                                                    return <em>Select Control</em>;
                                                return selected;
                                                }}
                                                onChange={(event) => ControlhandleChange(event,index)}
                                            >

                                                {inputTypes && Object.keys(inputTypes).sort().map((typeIndex)=>{
                                                    const typeItem = inputTypes.sort()
                                                        return(<MenuItem value={typeItem[typeIndex]}>{typeItem[typeIndex]}</MenuItem>)
                                                    })
                                                }

                                            </Select>

                                        </Grid>
                                        
                                        <Grid item xs={12} md={4}>
                                            <TextField id="standard-basic" name="value" onChange={(event) => ControlhandleChange(event,index)} label="Value" fullWidth value={item && item.value ?item.value:null}/>
                                        </Grid>
                                    </Grid>
                                </Box>



                                <Box>
                                    <IconButton style={{marginTop:"16px"}}  color="primary" aria-label="delete" component="span" onClick={(event) => ControlhandleDelete(event,index)}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                </Box>

                            </Box>)
                })}
                
            <Box className={classes.AddBox}>
              <Button className={classes.AddButton}  onClick={controlhandleAdd}>Add</Button>
            </Box>
                </FormControl>


                <br/>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                    </Grid>
                    <Grid item  xs={12} sm={6} className={classes.deleteGrid}>
                        <Button variant="contained" color="secondary" onClick={handleDelete}>
                            Delete
                        </Button>
                    </Grid>
                </Grid>
                
            </Box>

      </main>
        )
}



const mapStateToProps = (state) => {
    

    return {
        user:firebase.auth().currentUser,
        DevicesDetails: state.firestore.ordered.DevicesDetails && state.firestore.ordered.DevicesDetails[0] ? state.firestore.ordered.DevicesDetails[0] :null,
    }
}

export default compose(
    connect(mapStateToProps),
    
    firestoreConnect(props =>{
        return[
            { collection: 'Services', doc:"iot", storeAs: 'DevicesDetails',subcollections: [{ collection: 'Devices', doc:props.match.params.id}]},
        ];
  })
)(View);

