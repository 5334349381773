import AdSense from 'react-adsense';
import React, {Component } from 'react'

import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    AdBox: {
        width:"160px",
        height:"600px",
        background:"#eef"
      }
});

const VerticalAds = (props) => {
    const classes = useStyles();

    return (<Box className={classes.AdBox}>
        
    </Box>)
}

export default (VerticalAds);