import { NavLink } from 'react-router-dom'
import { compose } from 'redux'
import {baseURL} from "../../store/api/Access"
import  firebase  from 'firebase'
import {Container,FormControl,TextField,Button,Paper} from '@material-ui/core';
import Loader from '../../components/other/Loader'
//import {Access,baseURL} from "../../store/api/Access"
import axios from 'axios'
import { getFirebase } from 'react-redux-firebase'
import React, {Component,useState  } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        padding:"0 20px"
    },
    /*title:{
        fontSize: "45px;",
        color: "#374657;",
        fontWeight: "700;",
        fontFamily: "'Titillium Web', sans-serif",
        WebkitFontSmoothing: "antialiased",
        margin:"30px 0"
    },*/
    textField: {
      marginBottom: '15px',
    },/*
    SubmitButton:{
        backgroundColor:"#19A78E",
        fontSize: "16px;",
        color: "#fff;",
        fontWeight: "700;",
        textDecoration: "none",
        padding: "13px;",
        borderRadius: "50px;",
        marginBottom: '30px',
        width:"100px",
        "&:hover":{
            backgroundColor:"#374657",
        }
    },
    box:{
        border:"#19A78E solid 1px",
        padding: '30px',
        marginBottom:"100px"
    }*/
});

const View = ({user}) => {
    const classes = useStyles();

    const [values, setValues] = useState();
    const [device, setdevice] = useState({});
    const [{Loading,finished,error,timeoutTimer}, setForm] = useState({Loading:false, finished:false,error:false,timeoutTimer:null});

    if(finished)
        return (
            <main className={classes.root}>
                <h1>Add device</h1>

                <h3>Device Name: {device.name}</h3>
                <h3>Device ID: {device.id}</h3>
                    <h3>Device Key: {device.key}</h3>
                    <p>Key will not be diplayed again, please copy it and keep it save. Keys can be regenerated.</p>
                    <Button component={NavLink} to={"/devices/view/"+device.id} variant="contained" color="primary">
                    Go to device
                  </Button>
            </main>)


    if(error)
        return (
            <main className={classes.root}>
                <h1>Add device</h1>
                <h3>Error</h3>

                    <h3>Try again later.</h3>
            </main>)

    if(Loading)
        return (
            <main className={classes.root}>
                <h1>Add device</h1>
                <Loader/>
            </main>)


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleTimeout = () => {
        console.log()
        setForm({Loading:false, finished:false, error:true,timeoutTimer:null});
    };

    const handleSubmit = (event) => { 
        event.preventDefault();
        const timeoutTimer = setTimeout(handleTimeout, 30000)
        setForm({Loading:true, finished:false, error:false,timeoutTimer:timeoutTimer});

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            
                axios.post(baseURL+'/device/create',{name:values["name"]},{
                    "headers":{"Authorization": idToken}}).then(Result => {
                    if(timeoutTimer)
                        clearInterval(timeoutTimer);
                    
                    console.log(Result)
                    if(Result.data.device)
                        setdevice(Result.data.device);

                    setForm({Loading:false, finished:true, error:false,timeoutTimer:null});
                    
                }).catch(err => {
                    console.error("handleSubmit")
                    console.error(err)
                    //setError(err);
                    if(timeoutTimer)
                        clearInterval(timeoutTimer);
                    setForm({Loading:false, finished:false, error:true,timeoutTimer:null});
                });   
          }).catch(function(error) {
                if(timeoutTimer)
                    clearInterval(timeoutTimer);
                setForm({Loading:false, finished:false, error:true,timeoutTimer:null});
          });
    };

    return (
        <main className={classes.root}>
                <h1>Add device</h1>
        <p >Please add the name of the device</p>
        <form onSubmit={handleSubmit} action="post">
                <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    className={classes.textField}
                />
                
            <Button
                type="submit"
                color="primary"
                variant="contained"
                >
                Submit
            </Button>
        </form>
    </main>
        )
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser
    }
}

export default compose(
    connect(mapStateToProps),
)(View);