import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider,useSelector } from 'react-redux'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import {getFirebase, isLoaded } from 'react-redux-firebase'
import fbConfig from './store/api/fbConfig'
import thunk from 'redux-thunk'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import  firebase  from 'firebase'







const middleware = applyMiddleware(
  thunk.withExtraArgument({
      getFirebase,
      getFirestore
  })
);

const store = createStore(rootReducer,
  compose(
      middleware,
      //reactReduxFirebase(fbConfig,{attachAuthIsReady:true}),
      reduxFirestore(fbConfig)
  )
);


function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return (<div id="loader-wrapper">
  <div id="loader"></div>

  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>

</div>);
      return children
}


ReactDOM.render(
  <Provider store={store}>
      <ReactReduxFirebaseProvider
          firebase={firebase}
          config={fbConfig}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}>


          <AuthIsLoaded>
              <App />
          </AuthIsLoaded>

      </ReactReduxFirebaseProvider>
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
*/