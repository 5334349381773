import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import  firebase  from 'firebase'
import SiteLogo from "../other/SiteLogo"
//import {loginShow,Userlogout} from '../store/actions/authActions'

import { makeStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MemoryIcon from '@material-ui/icons/Memory';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import DirectionsIcon from '@material-ui/icons/Directions';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles(theme =>({
   ... mainStyle,
    root: {
      flexGrow: 1,
      zIndex:"1000",
      //position: "fixed;",
      width: "100%;"
    },
    Header: {
      backgroundColor:"#fff",
      backgroundImage:"url('/images/header-background.svg');",
      backgroundRepeat: "no-repeat;",
      backgroundPosition: "center;",
      backgroundSize:"cover;",
      borderBottom:"1px solid #E6E6E6", 
      paddingTop: "30px;",
      paddingBottom: "20px;",
      paddingRight: "20px;",
      paddingLeft: "20px;",
      
    },
    ToolBar:{
      padding:0,
      [theme.breakpoints.down("sm")]: {
        width:"100%",
        display: 'block;',
        textAlign: "center;"
      },
    },
    SiteIcon: {
      backgroundColor:"#19A78E",
      color:"#fff",
      padding:"10px",
      marginRight:"15px",
      borderRadius:"22px",
      //marginRight: theme.spacing(2),
    },
    title: {
      //flexGrow: 1,
      fontSize: "36px;",
      color: "#374657;",
      fontWeight: "700;",
      fontFamily: "'Titillium Web', sans-serif;",
      margin:0,
      marginRight:"20px",
    },
    Space:{
      flexGrow: 1,
    },
    Headermenu:{
      [theme.breakpoints.down("sm")]: {
        width:"100%",
        marginTop: "20px;",
      },
    },
    MenuItem:{
      color:"#8f9297",
      fontWeight: "700;",
      "&:hover":{
        background:"none",
        color:"#555"
      }
    },
    MenuItemSelected:{
      color:"#000"
      /*
      background: "#19A78E;",
      color:"#fff",
      padding:"20px",
      borderBottomLeftRadius:"10px",
      borderBottomRightRadius:"10px",
      paddingTop: "100px;",
      marginTop: "-80px;",
      marginRight:"10px",
      fontWeight: "700;",
      paddingBottom: "10px;",*/
    }
  }));

  



const Header = ({user}) => {    
    const classes = useStyles();

    const [subMenu, setSubMenu] = React.useState(null);

    const handleSubMenuClick = (event) => {
      setSubMenu(event.currentTarget);
    };

    const handleSubMenuClose = () => {
      setSubMenu(null);
    };
    
    var HeaderLinks = (<>
      <Button component={NavLink} to="/contact" className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
          Contact
      </Button >
      <Button component={NavLink} to="/documents" className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
          Documents
      </Button >
      <Button component={NavLink} to="/login" className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
        Login
      </Button>
    </>)

    if(user != null)
    {
      HeaderLinks = (<>
        <Button component={NavLink} to="/devices" className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
            Devices
        </Button >
        {/*<Button component={NavLink} to="/notifications" className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
            Notifications
      </Button > */}
        <Button  color="inherit" aria-controls="subMenu" aria-haspopup="true" onClick={handleSubMenuClick} className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
          <MenuIcon/>
        </Button>
        
        <Menu
              id="subMenu"
              anchorEl={subMenu}
              open={Boolean(subMenu)}
              onClose={handleSubMenuClose}
            >
              <MenuItem component={Link} target="_blank" href="https://account.spried.com/"  onClick={handleSubMenuClose}>Profile</MenuItem>
              <MenuItem component={NavLink} to="/contact"  onClick={handleSubMenuClose}>Contact</MenuItem>
              <MenuItem component={NavLink} to="/documents" onClick={handleSubMenuClose}>Documents</MenuItem>
              <Divider />
              <MenuItem component={Link} href="https://spried.com" target="_blank" onClick={handleSubMenuClose}>SPRIED</MenuItem>
              <MenuItem component={Link} href="https://feedback.spried.com/service/iot" target="_blank" onClick={handleSubMenuClose}>Feedback/Bug</MenuItem>
              <Divider />
              <MenuItem component={NavLink} to="/logout"  onClick={handleSubMenuClose}>Logout</MenuItem>
        </Menu>
  
      </>)
    }


    return (
        <div className={classes.root}>
            <header position="static" className={classes.Header}>
                    <Toolbar className={classes.ToolBar}>
                        <SiteLogo/>

                        <div className={classes.Space} />

                        <div className={classes.Headermenu} >
                          <Button exact component={NavLink} to="/" className={classes.MenuItem}  activeClassName={classes.MenuItemSelected}>
                              Home
                          </Button >

                          {HeaderLinks}
                        </div>
                    </Toolbar>
            </header>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser
    }
}

export default connect(mapStateToProps)(Header)