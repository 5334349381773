import AdSense from 'react-adsense';
import React, {Component } from 'react'

import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    AdBox: {
        width:"100%",
        height:"90px",
        background:"#eef",
        marginTop:"30px",
        marginBottom:"30px",
      }
});

const HorizontalAds = (props) => {
    const classes = useStyles();

    return (<Box className={classes.AdBox}>
        
        </Box>)
}

export default (HorizontalAds);