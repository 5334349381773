import React  from 'react'



import {
    Box,
      } from '@material-ui/core';


import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    }
});




const Settings = {
    "Name":"Stats",
    "Display":true
}


const View = ({DeviceData,DevicesDetails,Settings}) => {
    const classes = useStyles();

    return (
            <Box>
                <strong>Name:</strong> {DevicesDetails.name}<br/>
                <strong>ID:</strong> {DevicesDetails.DeviceId}<br/>
                <strong># Entries:</strong> {DevicesDetails.entries}<br/>
                <strong>Created:</strong> {Date(DevicesDetails.dateCreated*1000).toLocaleString("en-AU")}<br/>
                <strong>Last Entries: </strong>{Date(DevicesDetails.dateLastEntries*1000).toLocaleString("en-AU")}<br/>
                <strong>Updated:</strong> {Date(DevicesDetails.dateUpdated*1000).toLocaleString("en-AU")}
            </Box>
        )
}



export default {
    Settings,
    View
};