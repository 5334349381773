import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import DeviceHeader from '../../../components/header/DeviceHeader'


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import {
    Box,
    Button,
    Card,Switch,
    CardContent,
    ListItem,
    FormControl,
    Select,
    IconButton,
    List,
    Menu,
    Grid,
    TextField, 
    MenuItem,
    Checkbox,
    Toolbar,
    Typography
      } from '@material-ui/core';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MenuIcon from '@material-ui/icons/Menu';


import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../../assets/mainStyle";
const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    },
    HeaderBox:{
        padding: " 0 20px;",
    },
    ToolBar:{
        padding:0
    },
    MenuItem:{
      color:"#8f9297",
      fontWeight: "700;",
      padding:0,
      margin:0,
      marginRight:"10px",
      textAlign:"left",
      "&:hover":{
        background:"none",
        color:"#555"
      }
    },
    MenuItemSelected:{
      color:"#000"
    },
    AddBox:{
      marginTop:"20px",
      marginBottom:"60px",
      textAlign:"center"
    },
    AddButton:{
      padding:"10px 20px"

    }
});




const inputTypes={
  /*"Button":{"Value":true},*/
  "Switch":{"default":true},
  "Global":{},
  "Variable":{},
  "Time":{"Value":true},
  "Date":{"Value":true},
  "Select":{"multiple":true},
  "Text":{"Value":true},
  "Timer":{"Value":true},
  "Number":{"Value":true}
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {/*<CloseIcon />*/}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};





const View = (props) => {
    const classes = useStyles();
const {match,DevicesDetails} = props;

const [ControlList, setControlList] = React.useState(null);
const [Editor, setEditor] = React.useState(null);
const [ControlSelected, setControlSelected] = React.useState(null);

const [openMenu, setOpenMenu] = React.useState(null);
const [openEditor, setOpenEditor] = React.useState(false);


if(!ControlList && DevicesDetails)
{
  if(!DevicesDetails.Control)
    setControlList([{"type":"Variable","name":"submit", "value":"submit"}])
  else
    setControlList(DevicesDetails.Control)
}

const handleClickMenu = (event,index) => {
  setControlSelected(index)
  setOpenMenu(event.currentTarget);
};

const handleClickAdd = (event) => {
  setEditor({});
  setControlSelected(null)
  setOpenEditor(true);
};


const handleChangeEditor = (event) => {
  setEditor({
    ...Editor,
    [event.target.name]: event.target.value
  });
};


const handleClose = () => {
  setOpenMenu(null);
  setEditor(null);
  setOpenEditor(false);
};


const handleAddButton = ()  => {

  if(!ControlSelected)
  {
    var ControlListTemp = ControlList;
    ControlListTemp.push(Editor)
    setControlList(ControlListTemp);
  }
  else
  {

  }


  setControlSelected(null)
  handleClose()
};



const handleEdit = ()  => {
  handleClose()
  
  setEditor(ControlList[ControlSelected]);
  setOpenEditor(true);
}

const handleInformation = ()  => {
  //goto doc for ControlSelected
  setControlSelected(null)
  handleClose()
}

const handleDelete = ()  => {
  var ControlListTemp = ControlList;
  ControlListTemp.splice(ControlSelected, 1); 
  setControlList(ControlListTemp);
  
  setControlSelected(null)
  handleClose()
}



    return (
        <main className={classes.root}>
            <DeviceHeader DeviceId={match.params.id}/>
              <List>

              <BootstrapDialog
                /*onClose={handleClose}*/
                aria-labelledby="customized-dialog-title"
                open={openEditor}
              >
                <BootstrapDialogTitle id="customized-dialog-title"/* onClose={handleClose}*/>
                  {!ControlSelected ? "Add Control":"Edit Control"}
                  
                </BootstrapDialogTitle>
                <DialogContent dividers>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField id="standard-basic" name="name" onChange={handleChangeEditor} label="Name" fullWidth value={Editor?Editor.name:null}/>
                    </Grid>
                    

                    <Grid item xs={12}>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        fullWidth
                        label="Type"
                        displayEmpty
                        value={Editor?Editor.type:null}
                        name="type"
                        inputProps={{ 'aria-label': 'Without label' }}
                        renderValue={(selected) => {
                          if (!selected)
                            return <em>Select Control</em>;
                          return selected;
                        }}
                        onChange={handleChangeEditor}
                      >
                        {inputTypes && Object.keys(inputTypes).sort().map((item,index)=>{
                          return(<MenuItem value={item}>{item}</MenuItem>)
                          })
                        }
                      </Select>
                    </Grid>

                    {Editor && Editor.type && inputTypes[Editor.type].default ? 
                      <Grid item xs={12}>
                        <TextField id="standard-basic" name="default" onChange={handleChangeEditor} fullWidth label="Default Value"/>
                      </Grid>
                    : null}

                    {Editor && Editor.type && inputTypes[Editor.type].Value ? 
                      <Grid item xs={12}>
                        <TextField id="standard-basic" name="value" onChange={handleChangeEditor} fullWidth label="Value"/>
                      </Grid>
                    : null}

                    {Editor && Editor.type && inputTypes[Editor.type].multiple ? 
                      <Grid item xs={12}>
                        <TextField id="standard-basic" name="list" onChange={handleChangeEditor} fullWidth label="list your options"/>
                      </Grid>
                    : null}

                   

                  </Grid>

                </DialogContent>
                
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    Close
                  </Button>
                  <Button autoFocus onClick={handleAddButton}>
                    {!ControlSelected ? "Add":"Save"}
                  </Button>
                </DialogActions>
              </BootstrapDialog>


                <Menu
                  id="fade-menu"
                  anchorEl={openMenu}
                  keepMounted
                  open={openMenu ? true:false }
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleInformation}>Information</MenuItem>
                  <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>



                
              {ControlList && ControlList.map((item, index)=>{

                return(
                  <ListItem>
                  <Box flexGrow={1}>{item.name}</Box>
                  <Box>

                  {() => {
                    switch(item.type)
                    {
                      case "Button":
                        return (
                                    <Button variant="contained">{item.name}</Button>)
                      case "Switch":
                        return (
                                  <Switch 
                                          checked={item.value}
                                          /*onChange={handleChange}*/
                                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />)
                      case "Global":
                        return null
                      case "Variable":
                        return null
                      case "Time":
                        return (
                                  <TextField
                                  /* id="time"*/
                                    type="time"
                                    defaultValue="12:00"
                                    value={item.value}
                                    className={classes.textField}
                                  />)
                      case "Date":
                        return (
                                  <TextField /*id="date"*/ type="date" defaultValue="2020-01-01" className={classes.textField}/>)
                      case "Select":
                        return (
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Type"
                                  /* onChange={handleChange}*/
                                  >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                  </Select>)
                      case "Text":
                        return (<TextField id="standard-basic" values={item.value} />)
                      case "Timer":
                        return (<TextField id="standard-basic" values={item.value} type="number"/>)
                      case "Number":
                        return (<TextField id="standard-basic" type="number" values={item.value} />)
                    }
                    return null
                  }
              }
              
              </Box>
    <Box><MenuIcon index={index} onClick={(event) => handleClickMenu(event, index)}/></Box>
  </ListItem>
  
  )
          })}
            
            </List>

            

            <Box className={classes.AddBox}>
              <Button className={classes.AddButton}  onClick={handleClickAdd}>Add</Button>
            </Box>
        </main>
        )
}

const mapStateToProps = (state) => {
    
    var DevicesDetails = null;
    if(state.firestore.ordered.DevicesDetails)
        DevicesDetails = state.firestore.ordered.DevicesDetails[0];

    return {
        user:firebase.auth().currentUser,
        DevicesDetails: DevicesDetails,
        //Groups: state.firestore.ordered.Groups,
        //Group: state.firestore.ordered.Group,
    }
}


export default compose(
    connect(mapStateToProps),
    
    firestoreConnect(props =>{
        return[
          { collection: 'Services', doc:"iot", storeAs: 'DevicesDetails',subcollections: [{ collection: 'Devices', doc:props.match.params.id}]},
          
          
          
          /*{ collection: 'Devices', storeAs: 'DeviceData',doc:props.match.params.id,
          subcollections: [
            { collection: 'Data',orderBy:["dateCreated","desc"],limit:10},
          ]},*/
        ];
  })
)(View);