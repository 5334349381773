import React, {Component } from 'react'

import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles({
    loading:{
        alignContent: "center;",
        textAlign: "center;",
        paddingTop: "50px;",
        paddingBottom: "150px;",
    },
    CircularProgress:{
        color:"#19A78E"
    }
  });

const Loader = (props) => {
    const classes = useStyles();
    return <Box className={classes.loading}>
        <CircularProgress  className={classes.CircularProgress}/>
        <h4>Loading</h4>

    </Box>
}

export default (Loader);