import React from 'react'
import  axios  from 'axios'
import {
  AppBar,Divider,CssBaseline ,
  Link,Box,Drawer,ListItemText,Breadcrumbs,List,ListItem,
  ListItemIcon,IconButton,Typography,Toolbar
} from '@material-ui/core';
import  MenuIcon from '@material-ui/icons/Menu';
import  MailIcon from '@material-ui/icons/Mail';
import Slide from '@mui/material/Slide';
import { NavLink,withRouter  } from 'react-router-dom'

import Loader from '../../components/other/Loader'

import Page404 from './../Page404'
import PageError from './../PageError'
import parse from 'html-react-parser';


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'
import {getFirebase, isLoaded,isEmpty, } from 'react-redux-firebase'


import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles( {
  ... mainStyle,
  root:{
      textAlign: "center;",
      paddingTop: "20px;",
      marginBottom: "100px;",
  },
  SlideBox:{
    flexShrink: 1,
    borderRight:"1px solid #1B263B"
  },
  DocumentBox:{
    flexShrink: 1,
    margin:"0 20px"
  }
});

const drawerWidth = 240;




const SubContext = ({documentsList,document}) => {


  var found = false
  if(!documentsList)
  return null
  documentsList.forEach(DocumentItem => {
    
    if(DocumentItem.path && document.path && DocumentItem.path.startsWith(document.path) &&  DocumentItem.path != document.path)
    {
      found = true
    }
  })


  if(!found)
    return null

  return (<Box>
    <h2>Contents</h2>

    <List>
    {documentsList && documentsList.map((DocumentItem, index) => {

      if(DocumentItem.path && document.path && DocumentItem.path.startsWith(document.path) &&  DocumentItem.path != document.path)
      {
        return(<ListItem key={DocumentItem.name} component={NavLink} to={ DocumentItem.path ? "/documents" +DocumentItem.path : "/documents" }>
            <ListItemText  primary={DocumentItem.name} />
          </ListItem>
        )
      }

    })}
  </List></Box>)
}




const Documents = ({documentsList,document}) => {
  const classes = useStyles();
  const firebase = getFirebase()
  const [ error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [documentId, setdocumentId] = React.useState(null);
  const [checked, setChecked] = React.useState(true);
  
  const [DocumentText, setDocumentText] = React.useState({id:null, document:null});

  const containerRef = React.useRef(null);
  //var DataRef = React.useRef(null);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  
  
  var path = window.location.pathname.split("/"); 
  path.splice(0, 2);
  var pathString = path.join('-')

  if(pathString == "")
    pathString = "index"




  




  if(isLoaded(document) && !isEmpty(document) && pathString == document.id && documentId != document.id)
  {
    if(!document.file && loading)
    {
      //setError(true)
      setLoading(false)
    }
    else if(document.file)
    {
      console.log(document)
      const storage = firebase.storage()
      var gsReference = storage.refFromURL(document.file);
      
      gsReference.getDownloadURL().then((url) => {
        console.log("url");
        axios.get(url)
        .then(function (response) {
          if(isLoaded(document) && !isEmpty(document) && pathString == document.id && documentId != document.id)
          {
          setLoading(false)
          setError(false)
          console.log(response)
          setDocumentText({document:response.data, id:document.id})
          //DataRef.current.innerHTML = response.data;
          }
          
        })
        .catch(function (error) {
          console.log(error)
          setError(true)
          setLoading(false)
        })
      })
      .catch((error) => {
        console.log(error)
        setError(true)
        setLoading(false)
      });

      
      setLoading(true)
      setdocumentId(document.id)
    }
  }
  


    return (
      <Box >
              <CssBaseline />

        <AppBar position="static"  color="inherit">
        <Toolbar variant="dense">
          <IconButton onClick={handleChange} edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb">

            {/*path && path.map((item, index)=>{
              var pathName = item.charAt(0).toUpperCase() + item.slice(1).replace(/([A-Z])/g, ' $1').trim() 
              if(pathName && pathName[0]) pathName[0] = pathName[0].toUpperCase()
              return(<Link  component={NavLink} underline="hover" color="inherit" to={"/"+item}>
              {pathName}
            </Link>)
            })*/}

            <Typography color="text.primary">{/*documentName*/}</Typography>

          </Breadcrumbs>



          

        </Toolbar>
      </AppBar>


      
      <Box component="main" sx={{ display: 'flex', p: 1, bgcolor: 'background.paper' }}>

        <Slide direction="right" in={checked} className={classes.SlideBox}  container={containerRef.current}>
          <Box style={{ width: drawerWidth }}>
            <List>
              {documentsList && documentsList.map((DocumentItem, index) => {

                if(DocumentItem.path && DocumentItem.path.split('/').length-1 >1)
                return null

              return(
                <ListItem button key={DocumentItem.name} component={NavLink} to={ DocumentItem.path ? "/documents" +DocumentItem.path : "/documents" }>
                  <ListItemText  primary={DocumentItem.name} />
                </ListItem>)
              })}
            </List>
          </Box>
        </Slide>

        <Box className={classes.DocumentBox}>

        {

        !isLoaded(document) || loading ? 
            <main className={classes.root}>
                <h1>Loading Documents</h1>
                <Loader/>
            </main>
            :
            error ? <PageError/> : 

          isEmpty(document) ? <Page404/> : 
          <>
            {document.name ? <h1>{document.name}</h1>: null}

            {DocumentText.id && DocumentText.id == document.id &&  DocumentText.document ?
            <Box /*ref={DataRef}*/ id="MainDocumentHtml">
              {parse(DocumentText.document)}
            </Box>
            :null
}


            <SubContext documentsList={documentsList} document={document} />
          </>
          }
        </Box>
      </Box>


      {/*
      <Box component="main" sx={{ display: 'flex', p: 1, bgcolor: 'background.paper' }}>

<Slide direction="right" in={checked} className={classes.SlideBox}  container={containerRef.current}>
  <Box style={{ width: drawerWidth }}>
    <List>
      {documentsList && documentsList.map((DocumentItem, index) => (
        <ListItem button key={DocumentItem.name} component={NavLink} to={ DocumentItem.path ? "/documents/"+DocumentItem.path : "/documents" }>
          <ListItemText  primary={DocumentItem.name} />
        </ListItem>
      ))}
    </List>
  </Box>
</Slide>

<Box className={classes.DocumentBox}>
  {document.file ? <h1>{document.name}</h1>: null}
  <Box ref={DataRef} id="MainDocumentHtml">

  </Box>
</Box>
</Box>*/}

</Box>
        )
}







const mapStateToProps = (state) => {
  return {
      documentsList: state.firestore.ordered.documentsList  ? state.firestore.ordered.documentsList:null,
      document: state.firestore.ordered.document && state.firestore.ordered.document[0] ? state.firestore.ordered.document[0]:null,
  }
}


export default withRouter(compose(
  
  connect(mapStateToProps),
  firestoreConnect(props =>{

    var path = window.location.pathname.split("/");
    path.splice(0, 2);
    var pathString = path.join('-')

    if(pathString == "")
      pathString = "index"

      console.log("pathString")    
      console.log(pathString)      

    return[
      { collection: 'Documents', doc:"iot", storeAs: 'document', subcollections: [{ collection: 'pages', doc:pathString}] },
      { collection: 'Documents', doc:"iot", storeAs: 'documentsList', subcollections: [{ collection: 'pages', orderBy:"path"}] },
    ];
})
)(Documents));