import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'

import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import Select from '@material-ui/core/Select';

import Container from '@material-ui/core/Container';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Button,
    TextField ,
    FormControl,
    Card,
    InputLabel,
    CardContent,
    CardHeader,
    Divider,
    Grid, 
    MenuItem,
  Checkbox,
  Toolbar,
  FormControlLabel,
  Typography
      } from '@material-ui/core';

      import MenuIcon from '@material-ui/icons/Menu';
      
import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../assets/mainStyle";


const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    },
    ListItem:{
        background:"#eee",
        borderBottom: "1px rgba(0, 0, 0, 0.12) solid;"

    },
    ListItemAddButton:{
        background:"#eee",
        justifyContent: 'center'
    }
});

const View = (props) => {
    const classes = useStyles();
    const {form} = props;
    const [formvalues, setformValues] = useState(form);

    if(!formvalues)
        setformValues([]);


    const handleAdd = () => {
        var formvaluesTemp = formvalues;
        formvaluesTemp.push({name:"",type:"text",discription:"",value:[] });
        setformValues(formvaluesTemp);
    };
    
    const handleRemove = (index) => {
        var formvaluesTemp = formvalues;
        formvaluesTemp.splice(index, 1);
        setformValues(formvaluesTemp);
    };
    
    const handleValueAdd = () => {
        var formvaluesTemp = formvalues;
        if(!formvaluesTemp.value)
            formvaluesTemp.value = [];
        formvaluesTemp.value.push({name:""});
        setformValues(formvaluesTemp);
    };
    
    const handleValueRemove = (index) => {
        var formvaluesTemp = formvalues;
        formvaluesTemp.value.splice(index, 1);
        setformValues(formvaluesTemp);
    };
    
/*
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
*/






    return (
            <List>
                { formvalues && formvalues.map((headerRow, headerIndex) => {

                    return (   
                        <ListItem className={classes.ListItem}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                                    <Select
                                        fullWidth
                                        labelId="Select"
                                        id="format"
                                        value={headerRow.type}
                                    >
                                        <MenuItem value="text">Text</MenuItem>
                                        <MenuItem value="number">Number</MenuItem>
                                        <MenuItem value="switch">Switch</MenuItem>
                                        <MenuItem value="Select">Select</MenuItem>
                                        <MenuItem value="varable">Varable</MenuItem>
                                        <MenuItem value="checkbox">Checkbox</MenuItem>
                                    </Select>
                                </Grid>


                                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                                    <TextField fullWidth id="Name" type="text"  value={headerRow.name}/>
                                </Grid>


                                <Grid item xl={7} lg={7} md={5} sm={12} xs={12}>
                                    <TextField fullWidth id="Discription" type="text"  value={headerRow.discription}/>
                                    
                                    <Button color="inherit" >
                                        <MenuIcon/>
                                    </Button>
                                </Grid>



                                {/* values; dafault, select/checkbox values */}
                                {/* more setting/advanced  */}


                            </Grid>
                        </ListItem> 
                    )
                })}

                <ListItem className={classes.ListItemAddButton}>
                    <Button variant="contained" onClick={handleAdd} color="primary">
                        Add Input
                    </Button>
                </ListItem>
            </List>
        )
}




export default (View);

