import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Page404 from './views/Page404' 
import Maintenance from './views/Maintenance' 

import Home from './views/Home'

import Login from './views/auth/Login'
import Logout from './views/auth/Logout'
import Contact from './views/Contact'
import DevicesList from './views/Devices/List'
import privacypolicy from './views/privacypolicy'
import termsofservice from './views/termsofservice'
import Documents from './views/Documents/Documents'

import AddDevice from './views/Devices/Add'
import DataDevice from './views/Devices/View/Data'
import ErrorsDevice from './views/Devices/View/Errors'
import ViewDisplay from './views/Devices/View/Display'
import ControlDevice from './views/Devices/View/Control'
import ActionDevice from './views/Devices/View/Action'
import AddActionDevice from './views/Devices/View/AddAction'
import SettingsDevice from './views/Devices/View/Settings'


import Notifications from './views/Notifications'

/*
router.post('/', ApiCtrl.index);

router.post('/connect', ConnectCtrl.index);

router.post('/list', DeviceCtrl.device);
router.post('/get', DeviceCtrl.get);
//router.post('/data', DeviceCtrl.issue);
router.post('/create', DeviceCtrl.create);
router.post('/update', DeviceCtrl.update);
router.post('/remove', DeviceCtrl.remove);

router.post('/group', GroupCtrl.issue);
router.post('/group/list', GroupCtrl.list);
router.post('/group/get', GroupCtrl.get);
router.post('/group/create', GroupCtrl.create);
router.post('/group/update', GroupCtrl.update);
router.post('/group/remove', GroupCtrl.remove);

router.post('/profile', ProfileCtrl.profile);
router.post('/profile/update', ProfileCtrl.update);
router.post('/profile/get', ProfileCtrl.get);
router.post('/profile/notifications', ProfileCtrl.notifications);
*/

function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Header />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/logout' component={Logout}/>
            <Route exact path='/login' component={Login}/>
            <Route exact path='/contact' component={Contact}/>
            <Route exact path='/notifications' component={Notifications}/>
            <Route exact path='/privacypolicy' component={privacypolicy} />
            <Route exact path='/termsofservice' component={termsofservice} />
            
            <Route path='/documents/' component={Documents}/>
            {/*<Route exact path='/documents/getting-started' component={GettingStarted} />*/}

            <Route exact path='/devices' component={DevicesList} />
            <Route exact path='/devices/add' component={AddDevice} />
            
            <Route exact path='/devices/view/:id/data' component={DataDevice} />
            <Route exact path='/devices/view/:id/control' component={ControlDevice} />
            <Route exact path='/devices/view/:id/errors' component={ErrorsDevice} />
            <Route exact path='/devices/view/:id/action/add' component={AddActionDevice} />
            <Route exact path='/devices/view/:id/action' component={ActionDevice} />
            <Route exact path='/devices/view/:id/settings' component={SettingsDevice} />
            <Route exact path='/devices/view/:id' component={ViewDisplay} />

            <Route exact path='/groups' component={Maintenance} />
            <Route exact path='/groups/view/:id' component={DevicesList} />

{/*            <Route exact path='/actions' component={Maintenance} />
*/}            
            
            {/*
                - device list
                - group list
                - group
                - device
                - add device
                - add group
            */}

            <Route component={Page404}  status={404}/>

          </Switch>
          <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
