import React from 'react'

import {
    Box,
      } from '@material-ui/core';


import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    }
});


const Settings = {
    "Name":"Text",
    "Display":true,
    "options":
    {
        "view":null,
        "settings":[
            {
              "id":"text",
              "type":"textarea",
              "name":"text"
            }
          ],
        "grid":true,
    }
}


const View = ({DeviceData,DevicesDetails,Settings}) => {
    const classes = useStyles();

    return (
            <Box>
              {Settings.text ? Settings.text : null}
            </Box>
        )
}



export default {
    Settings,
    View
};